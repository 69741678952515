import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import Container from '~/components/Container'
import Layout from '~/components/Layout'
import Seo from '~/components/Seo'

import * as classes from './ContentPage.module.scss'

const ConentPage = ({ data }) => {
  const {
    content,
    page_title: title,
    seo_title: seoTitle,
    seo_description: seoDescription,
  } = data.prismicPage.data

  return (
    <Layout>
      <Seo title={seoTitle?.text} description={seoDescription?.text} />
      <section className={classes.contentPage}>
        <h1 className={classes.title}>{title.text}</h1>
        {content &&
          content.map(cnt => (
            <Container>
              <div className={classes.contentContainer}>
                <RichText render={cnt.text.raw} />
              </div>
            </Container>
          ))}
      </section>
    </Layout>
  )
}

export default ConentPage

export const query = graphql`
  query page($id: String) {
    prismicPage(id: { eq: $id }) {
      id
      uid
      data {
        content {
          text {
            raw
          }
        }
        page_title {
          text
        }
        seo_description {
          text
        }
        seo_title {
          text
        }
      }
    }
  }
`
